function _typeof(obj){"@babel/helpers - typeof";return _typeof="function"==typeof Symbol&&"symbol"==typeof Symbol.iterator?function(obj){return typeof obj;}:function(obj){return obj&&"function"==typeof Symbol&&obj.constructor===Symbol&&obj!==Symbol.prototype?"symbol":typeof obj;},_typeof(obj);}function ownKeys(object,enumerableOnly){var keys=Object.keys(object);if(Object.getOwnPropertySymbols){var symbols=Object.getOwnPropertySymbols(object);enumerableOnly&&(symbols=symbols.filter(function(sym){return Object.getOwnPropertyDescriptor(object,sym).enumerable;})),keys.push.apply(keys,symbols);}return keys;}function _objectSpread(target){for(var i=1;i<arguments.length;i++){var source=null!=arguments[i]?arguments[i]:{};i%2?ownKeys(Object(source),!0).forEach(function(key){_defineProperty(target,key,source[key]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(target,Object.getOwnPropertyDescriptors(source)):ownKeys(Object(source)).forEach(function(key){Object.defineProperty(target,key,Object.getOwnPropertyDescriptor(source,key));});}return target;}function _defineProperty(obj,key,value){key=_toPropertyKey(key);if(key in obj){Object.defineProperty(obj,key,{value:value,enumerable:true,configurable:true,writable:true});}else{obj[key]=value;}return obj;}function _toPropertyKey(arg){var key=_toPrimitive(arg,"string");return _typeof(key)==="symbol"?key:String(key);}function _toPrimitive(input,hint){if(_typeof(input)!=="object"||input===null)return input;var prim=input[Symbol.toPrimitive];if(prim!==undefined){var res=prim.call(input,hint||"default");if(_typeof(res)!=="object")return res;throw new TypeError("@@toPrimitive must return a primitive value.");}return(hint==="string"?String:Number)(input);}import{setCookie as cookiesNextSetCookie}from"cookies-next";export var defaultCookieExpiry=60*60*12;// 12 hours (60 sec * 60 min * 12 hr)
/**
 * For standardization, please create wrapper functions when consuming cookies
 * get/set/delete. Check out the technical design in Confluence or
 * /lib/controllers/verify/cookies for an example.
 *
 * @param pagePath The URL path of the area you'll be using the cookies for
 * @param key The key of the key-value pair that you're storing in cookies
 * @param value The value of the key-value pair that you're storing in cookies
 * @param options Used when accessing cookies on server-side rendering. Refer to cookies-next documentation for more information.
 * @param maxAge The max lifespan of the cookie in seconds. Defaults to our NextAuth's expiry.
 */export var setCookie=function setCookie(_ref){var pagePath=_ref.pagePath,key=_ref.key,value=_ref.value,options=_ref.options,maxAge=_ref.maxAge;// Note: Setting secure = true for Safari will cause cookies to not be set because locahost is not a secure domain
// However, Chrome and Firefox will set the cookie with secure = true
var secure=true;cookiesNextSetCookie(key,value,_objectSpread(_objectSpread({},options),{},{path:pagePath,maxAge:maxAge||defaultCookieExpiry,secure:secure}));};